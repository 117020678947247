import LeadGeneratorLoadable from "../../../components/generator/LeadGeneratorLoadable"
import React, { useContext, useEffect } from "react"
import LayoutContext from "../../../components/LayoutContext"
import { Helmet } from "react-helmet"

const IframeImmobilienBewertung = () => {
  const [layout, setLayout] = useContext(LayoutContext)

  useEffect(() => {
    setLayout(state => ({
      ...state,
      hide: true,
      stopTrack: true,
    }))
    return function unmount() {
      setLayout(state => ({
        ...state,
        hide: false,
        stopTrack: false,
      }))
    }
  }, [])

  const fixedPathname = `/de/iframe/immobilienbewertung/`
  return (
    <>
      <Helmet>
        <script src="/iframeResizer.contentWindow.min.js" />
      </Helmet>
      <LeadGeneratorLoadable leadGenerator="rating" setPath={fixedPathname} />
    </>
  )
}

export default IframeImmobilienBewertung
